export default {
  tokenWhiteList: [
    'Login',
    'Page404',
    'TestXysTest1',
    'TestXysTest2',
    'TestXysTest3',
    'ShowV2'
  ],
  loginPath:'/login',
  tokenKey:'token',
  // apiHost:'https://zutai-test.web.xinzhidi.cn/api',
  apiHost:'https://zutai.web.xinzhidi.cn/api',
  // apiHost:'http://localhost:7201',
  appName:'XZD组态',
  appEnName:'XZD Configuration',
}